<template>
<!--  :getPopupContainer="triggerNode =>  triggerNode.parentNode|| document.body"加上这个会导致第一次弹出位置不对-->
  <a-popover :title="title" trigger="hover" overlayClassName="axcbdj9901" autoAdjustOverflow>
    <template slot="content">
      <slot/>
    </template>
      <i class="iconfont icon-wentitishi toast"></i>
  </a-popover>
</template>
<script>
export default {
  name: "HeaderPop",
  props:['title']
};
</script>

<style lang="less">
  .axcbdj9901{
    .ant-popover-inner{
      padding:0 16px;
    }
    .ant-popover-title{
      padding:12px 0px 12px 0px;
      box-sizing: border-box;
      min-width:150px;
      font-size: 16px;
    }
    .ant-popover-inner-content{
      padding:12px 0px 12px 0px;
      font-size: 14px;
    }
  }
  .toast {
      color:#00AAA6;
      cursor: pointer;
      font-size: 16px;
    }
  
</style>
