

import http from "@/utils/request";
// 获取销售组织
export function getSaleList(data) {
    return http({
        method: "post",
        url: "/channelPriceProduct/agentOrg.nd",
        data
    });
}
// 获取物料组数据

export function getMatkl(data) {
    return http({
        method: "post",
        url: "comm/queryMatkl.nd",
        data
    });
}

// getproductSeach 模糊搜索数据

export function getproductSeach(data) {
    return http({
        method: "get",
        url: "/channelPriceDistributor/getDefevtiveProByMatkl.nd",
        params:data
    });
}

// 判断是否有统一价格
export function hasLockPrice(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/hasLockPriceBy.nd",
        data
    });
}

// 商家账号模糊搜索
export function getCustCisCode(data) {
    return http({
        method: "get",
        url: "/channelPriceDistributor/getCustCisCode.nd",
        params:data
    });
}

// 是否有指导价
export function hasUnLockPriceBy(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/hasUnLockPriceBy.nd",
        data
    });
}
// 保存接口（是否为存为草稿接口？需确认，传参需要确认 ）
export function savePrice(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/savePrice.nd",
        data
    });
}
// 保存并发布
export function saveAndPublish(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/saveAndPublish.nd",
        data
    });
}
// 获取渠道价格
export function checkValid(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/checkValid.nd",
        data
    });
}

// 编辑获取详情
export function getEditInfo(data) {
    return http({
        method: "post",
        url: "channelPriceDistributor/toEdit.htm",
        data
    });
}
// 修改并发布
export function updateAndPublish(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/updateAndPublish.nd",
        data
    });
}
export function updatePrice(data) {
    return http({
        method: "post",
        url: "/channelPriceDistributor/updatePrice.nd",
        data
    });
}
