import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";
//我的库存列表
export function getHomepageNormalSalesReport(data) {
    return dmshttp({
        url: 'normalSalesReport',
        data
    });
}
//我的库存列表导出
export function exportStock(data,url) {
    return dmshttp({
        url: url,
        data,
        responseType: 'blob'
    });
}
//我的库存列表
export function getStockold(data) {
    return dmshttp({
        url: "findInventoryList",
        data
    });
}
//我的库存列表
export function findBaseData(data) {
    return dmshttp({
        url: "findBaseData",
        data
    });
}

// 报表数据
export function normalSalesReport(data) {
    return dmshttp({
        url: "normalSalesReport",
        data
    });
}

export function agentDict(data) {
    return http({
        url: "/comm/agentDict.nd",
        method: 'post',
        data
    });
}
