<template>
  <a-spin class="mid-spin" :spinning="false" size="large">
    <div class="formFiltrate-box clearfix" :class="{ more: isShowMoreFlag }">
      <div class="tool-box">
        <div class="btn-group">
          <h-btn :width="66" :height="32" content="搜索" @click="searchFun" />
          <h-btn
            :width="66"
            :height="32"
            :customType="3"
            content="重置"
            @click="resetFun"
          />
        </div>
        <h-btn
          v-if="isToggleFlag"
          class="zk"
          :width="40"
          :height="28"
          :content="isShowMoreFlag ? '收起' : '展开'"
          :level="3"
          @click="isShowMoreFlag = !isShowMoreFlag"
        />
      </div>
      <!-- <ul class="form clearfix" :style="{'padding-left': formDistance + 'px'}"> -->
      <ul class="form">
        <li>
          <span :style="{ width: 3 * 14 + 6 + 'px' }">品类:</span>
          <div class="form-item-box">
            <a-select
              v-model="filForm.materialGroupCode"
              :maxTagCount="2"
              showSearch
              showArrow
              allowClear
              placeholder="请选择"
            >
              <a-select-option
                v-for="option in matklList"
                :key="option.code"
                :value="option.code"
                >{{ option.name }}
              </a-select-option>
            </a-select>
          </div>
        </li>
        <li>
          <span :style="{ width: 3 * 14 + 6 + 'px' }">时间:</span>
          <div class="form-item-box">
            <a-month-picker
              :allowClear="false"
              @change="dateChange"
              v-model="filForm.date"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-month-picker>
          </div>
        </li>
      </ul>
    </div>
  </a-spin>
</template>
<script>
import { getRegin } from "@/utils/api";
import { fxDictCisCode, findBaseData } from "../api";
import moment from "moment";

export default {
  name: "InventoryFormFiltrate",
  data() {
    return {
      matklList: [],
      itemMLWidth: 90,
      maxSize: [], //每列最大字数
      isToggleFlag: false, //是否显示‘收起/展开按钮’ （是否筛选项超过6条）
      isShowMoreFlag: false, //true->收起（已展开）false->展开（已收起）
      filForm: {
        materialGroupCode: "",
        materialGroupName: "全品类",
        date: ""
      }
    };
  },
  props: {
    //设置表单之间边距
    itemDistance: {
      type: [Number, String],
      default: 58
    },
    //表单整体左边距
    formDistance: {
      type: [Number, String],
      default: 44
    },
    isNextRequest: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.filForm.date = moment(new Date()).format("YYYY-MM");
    // fxDictCisCode().then(res=>{
    //   this.orgList = res.data.list||[]
    // })
    findBaseData({
      cisCode: this.$store.state.user.userInfo.customer.cisCode,
      loginType: "CS",
      type: "wlz",
      userAccount: this.$store.state.user.userInfo.customer.cisCode
    }).then(res => {
      this.matklList = [];
      let data;
      if (typeof res.data == "string") {
        data = JSON.parse(res.data);
      } else {
        data = res.data;
      }
      (data.data || []).map(it => {
        for (const item in it) {
          this.matklList.push({
            code: item,
            name: it[item]
          });
        }
      });
    });
    this.callBack();
  },
  mounted() {},
  methods: {
    //搜索
    searchFun() {
      this.callBack();
    },
    //重置
    resetFun() {
      // this.$refs['form'].resetFields();
      Object.keys(this.filForm).forEach(key => {
        this.filForm[key] = undefined;
      });
      this.callBack();
    },
    onChange(value) {
    },
    dateChange(e) {
      this.filForm.date = moment(e._d).format("YYYY-MM");
    },
    //回调传出
    callBack() {
      let materialGroupName = "全品类";
      this.matklList.forEach(it => {
        if (it.code == this.filForm.materialGroupCode) {
          materialGroupName = it.name;
        }
      });
      this.$emit("search", { ...this.filForm, materialGroupName });
    },
    //日期选择
    indateChange(date, dateString, targetId) {
      this.filForm[targetId] =
        date && date.length > 0 ? dateString.join("_") : null;
    },
    //数字选择
    innumChange(targetId) {
      this.filForm[targetId] =
        (this.filForm[targetId + "innum1"]
          ? this.filForm[targetId + "innum1"]
          : "") +
        "_" +
        (this.filForm[targetId + "innum2"]
          ? this.filForm[targetId + "innum2"]
          : "");
    },
    //样式
    setStyle(idx) {
      let style = {};
      //设置两条表单之间边距
      if ((idx + 1) % 3) {
        style.marginRight = this.itemDistance + "px";
      }

      return style;
    }
  }
};
</script>

<style lang="less" scoped>
.formFiltrate-box {
  width: 1188px;
  margin: 0 auto;
  height: 178px;
  overflow: hidden;
  background: #fff;
  position: relative;
  transition: all 0.2s;

  &.more {
    height: auto;
  }

  .tool-box {
    position: absolute;
    width: 224px;
    height: 32px;
    top: 16px;
    right: 0px;

    button {
      float: left;
      letter-spacing: 1px;
      padding: 0;

      + button {
        margin-left: 16px;
      }
    }

    .zk {
      margin-top: 3px;
      margin-right: 18px;
      float: right;
    }
  }

  .form {
    // // max-width: 1040px;
    // height: auto;
    // // padding: 66px 40px 0;
    // padding: 66px 0px 0;
    // float: left;
    width: 100%;
    height: auto;
    padding: 66px 75px 0 52px;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // .ant-form-item {
    //   width: 33.33%;
    //   float: left;
    // }
    li {
      float: left;
      margin-bottom: 24px;

      > span {
        float: left;
        font-size: 14px;
        color: #262626;
        text-align: right;
        line-height: 32px;
      }

      .form-item-box {
        width: 230px;
        height: 32px;
        // border: 1px solid;
        float: left;
        margin-left: 10px;
      }
    }
  }

  // 表单样式
  //输入框
  /deep/ .ant-input-affix-wrapper .ant-input {
    padding: 0 16px;
    line-height: 32px;
    color: #262626;
    font-size: 14px;
    background-color: #fff;
    // border: 1px solid #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  //下拉选择框
  /deep/ .ant-select {
    width: 100%;

    .ant-select-selection__rendered {
      margin-right: 24px;
      overflow: hidden;

      ul {
        width: 999px;
      }
    }

    .ant-select-selection {
      border: 1px solid #ddd;
    }

    .ant-select-selection__rendered {
      margin-left: 16px;
    }
  }

  //日期选择框
  /deep/ .ant-calendar-picker {
    width: 100%;

    .ant-calendar-picker-input {
      border: 1px solid #ddd;

      &:hover {
        border-color: #aaa;
      }
    }

    .ant-calendar-picker-input.ant-input {
      text-align: left;
    }

    .ant-calendar-range-picker-input {
      width: 39%;
    }
  }

  /deep/ .ant-cascader-picker {
    width: 100%;
  }

  //数字选择框
  .number-range-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-input-number {
      width: 44%;
    }
  }
}
</style>
